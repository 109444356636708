import React from 'react';
import { motion } from 'framer-motion';
import { Analytics } from '@vercel/analytics/react';
import './App.scss';

function App() {
  return (
    <div className="App">
      {/* Header Section */}
      <header className="header">
        <motion.picture
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <source srcSet="/profile.webp" type="image/webp" />
          <img src="/profile.jpg" alt="Profile of Philip Moran" className="profile-img" loading="lazy" />
        </motion.picture>
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Philip Moran
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          Traveling The World Seeing Things, Building Things, and Trading Things
        </motion.p>
      </header>

      {/* Links Section */}
      <section className="links">
        {/* Link for Modern Money Methods */}
        <motion.a
          href="https://modernmoneymethods.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="link-button mmm-button" // Add specific class
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <picture>
            <source srcSet="/mmmlogo.webp" type="image/webp" />
            <img src="/mmmlogo.png" alt="Modern Money Methods Logo" className="link-icon" loading="lazy" />
          </picture>
          <span>Modern Money Methods</span>
        </motion.a>
        <motion.a
          href="https://www.instagram.com/philip_moran1212/"
          target="_blank"
          rel="noopener noreferrer"
          className="link-button instagram-button" // Add specific class
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <picture>
            <source srcSet="/iglogo.webp" type="image/webp" />
            <img src="/iglogo.png" alt="Instagram Logo" className="link-icon" loading="lazy" />
          </picture>
          <span>Instagram</span>
        </motion.a>
        <motion.a
          href="https://x.com/MMMethod12"
          target="_blank"
          rel="noopener noreferrer"
          className="link-button x-button" // Add specific class
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1.0 }}
        >
          <picture>
            <source srcSet="/xlogo.webp" type="image/webp" />
            <img src="/xlogo.png" alt="X Logo" className="link-icon" loading="lazy" />
          </picture>
          <span>X (Twitter)</span>
        </motion.a>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1.2 }}
        >

        </motion.p>
      </footer>
      <Analytics />
    </div>
  );
}

export default App;




